<template>
  <div class="widgetContainer widgetContainer--scrollable edit-manager">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <div class="title">
          Edit Manager
        </div>
        <i class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body edit-manager__body">
      <ListWithInfo :items="managerInfo" />
      <template v-if="accounts.length">
        <div v-for="(group,index) in groupedAccounts" :key="index" class="mb-2">
          <div style="font-size: 20px; font-weight: bold; margin-bottom: .5rem">
            {{ group.businessName }}
          </div>
          <el-card class="mb-1" :class="{'selected': isSelected(account)}" v-for="account in group.accounts"
                   :key="account.id" @click.native="toggleSelect(account)">
            <ul>
              <li style="display: flex; justify-content: space-between; align-items: center">
                <el-switch :value="isSelected(account)" />
                <div style="width: 80%">
                  <span>{{ account.label }}</span>
                  <div>{{ account.accountNumber }}</div>
                </div>
              </li>
            </ul>
          </el-card>
        </div>
      </template>
    </div>
    <div class="widgetContainer__footer--fixed">
      <div class="px-2">
        <el-button type="danger" @click="removeManager">
          Remove Manager
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ListWithInfo from '@/components/ListWithInfo';

export default {
  name: 'EditManager',
  components: {ListWithInfo},
  data() {
    return {
      accounts: [],
      selectedAccounts: []
    }
  },
  created() {
    this.accounts = this.getAllAccount
    this.selectedAccounts = this.getAllAccount.filter(acc => this.manager.accounts.find(ac => ac.account_id === acc.id))
  },
  computed: {
    managerInfo() {
      return [
        {label: 'Manager :', value: this.manager.user.name},
        {label: 'Email :', value: this.manager.user.email},
      ]
    },
    ...mapGetters('account', ['getAllAccount']),
    ...mapGetters({
      'manager': 'dashboard/getManager'
    }),
    groupedAccounts() {
      const fnPushAccountToProp = (accounts, acc, prop) => {
        if (!Object.hasOwnProperty.call(accounts, prop)) {
          const businessName = this.$store.getters['kyb/getBusinessList'].find(business => business.id === acc.businessId)?.legalName
          if (acc.type === 'businessChecking' && !businessName) return false;
          accounts[prop] = {
            businessName: businessName ?? prop,
            accounts: []
          }
        }
        accounts[prop].accounts.push(acc);
      }
      let accounts = {}
      this.accounts.forEach(acc => {
        if (acc.type === 'businessChecking') {
          fnPushAccountToProp(accounts, acc, acc.businessId)
        } else {
          fnPushAccountToProp(accounts, acc, 'Personal Accounts')
        }
      })
      return accounts;
    }
  },
  methods: {
    isSelected(account) {
      return !!this.selectedAccounts.find(acc => acc.id === account.id)
    },
    async toggleSelect(account) {
      const loader = this.showLoader()
      if (this.isSelected(account)) {
        await this.removeAccount(account)
        this.$message({
          message: 'Account successfully removed!',
          type: 'info'
        })
        this.selectedAccounts = this.selectedAccounts.filter(ac => ac.id !== account.id)
      } else {
        await this.addAccount(account)
        this.$message({
          message: 'Account added successfully!',
          type: 'success'
        })
        this.selectedAccounts.push(account)
      }
      loader.close()
    },
    async removeAccount(account) {
      await this.$serverRequest.delete('/v1/pilot/account', {
        data: {
          manager_id: this.manager.id,
          account_id: account.id
        }
      })
    },
    async addAccount(account) {
      await this.$serverRequest.post('/v1/pilot/account', {
        manager_id: this.manager.id,
        account_id: account.id
      })
    },
    removeManager() {
      this.$confirm(`Remove ${this.manager.user.name} as Manager`, 'Remove Manager', {
        type: 'warning',
        confirmButtonText: 'Remove'
      }).then(() => {
        const loader = this.showLoader()
        this.$serverRequest.delete('/v1/pilot', {data: {manager_id: this.manager.id}})
          .then(() => {
            this.$notify({
              title: 'Manager removed',
              message: `${this.manager.user.email} has been removed as manager.`,
              type: 'success'
            })
            this.drawerBack()
          }).catch(e => this.apiErrorMessage(e))
          .finally(() => loader.close())
      })
    }
  }
}
</script>

<style lang="scss">
.edit-manager {
  .edit-manager__body.widgetContainer__body {
    .el-card {
      &.selected {
        background: var(--branding-primary);
        color: white;
      }

      &:hover {
        background: aliceblue;
        color: black;
        cursor: pointer;
      }
    }
  }
}
</style>