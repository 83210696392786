<template>
  <div class="listWithInfo border-1">
    <ul>
      <li v-for="(item,index) in items" :key="index">
        <p class="label" v-text="item.label" />
        <p class="value" v-text="item.value" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props:{
    items: {
      type: Array,
      default: () => []
    }
  },
  name: 'ListWithInfo'
}
</script>

<style scoped>

</style>